import React from 'react';
import Img from 'gatsby-image';
import t, { _t } from '../../../utils/translate';
import _ from 'lodash';
import classNames from 'classnames';
import styles from './InfoBlock.module.scss';
import { fluidImagePropHelper } from '../../../utils/imagePropHelper';
import Vector from '../../Vector/Vector';

const InfoBlock = ({ headline, content, textField, location, dark }) => {
  const { pathname } = location;
  let inverted = false;

  if (
    pathname === '/om-oss' ||
    pathname === '/besok' ||
    pathname === '/opplevelser'
  ) {
    inverted = true;
  }

  if (content[0]._type === 'location') {
    let imageProp = fluidImagePropHelper(content[0].image.asset.id, 800, 780);
    return (
      <div
        className={classNames(
          inverted ? styles.inverted : undefined,
          styles.location,
          styles.infoBlockOuter,
          dark ? styles.dark : undefined
        )}
        id={_.camelCase(_t(headline))}
      >
        <Img
          alt={content[0].image.alt}
          className={styles.image}
          fluid={imageProp}
        />
        <div className={styles.textOuter}>
          <h3 className={classNames('headline-40', styles.headline)}>
            {_t(headline)}
          </h3>
          {textField && (
            <p className={classNames(styles.textField, 'body-text-s')}>
              {_t(textField)}
            </p>
          )}
          {content[0].links.map((link, index) => {
            const { links, urlIcon } = link;
            return (
              <a
                className={classNames(styles.links, 'body-text-s')}
                key={index}
                href={links.linkTo[0].href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg className={styles.icon}>
                  <image
                    xlinkHref={urlIcon.asset.url}
                    src={urlIcon.asset.url}
                  />
                </svg>
                {_t(links.text)}
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  if (content[0]._type === 'hours') {
    const splitHours = _t(content[0].hours).split('\n');

    return (
      <div
        className={classNames(
          inverted ? styles.inverted : undefined,
          styles.infoBlockOuter
        )}
        id={_.camelCase(_t(headline))}
      >
        <h3 className={classNames('headline-40', styles.headline)}>
          {_t(headline)}
        </h3>
        {textField.nb && <p className={styles.textField}>{_t(textField)}</p>}
        {splitHours.map((hour, index) => (
          <p
            key={index}
            className={classNames(styles.hoursText, 'body-text-s')}
          >
            {hour}
          </p>
        ))}
      </div>
    );
  }

  if (content[0]._type === 'ticketPrices') {
    return (
      <div
        className={classNames(
          inverted ? styles.inverted : undefined,
          styles.infoBlockOuter
        )}
        id={_.camelCase(_t(headline))}
      >
        <div className={styles.priceTextOuter}>
          <h3 className={classNames('headline-40', styles.headline)}>
            {_t(headline)}
          </h3>
          {textField && (
            <p className={classNames(styles.textField, 'body-text-s')}>
              {_t(textField)}
            </p>
          )}
        </div>
        <div className={styles.ticketPricesOuter}>
          {content[0].prices.map((price, index) => {
            return (
              <div className={styles.priceRowOuter} key={index}>
                <div
                  className={classNames(styles.priceRowInner, 'body-text-s')}
                >
                  <span>{_t(price.priceCategory)}</span>
                  <span>{price.price},-</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.priceLinkOuter}>
          {content[0].deactivateButton ? (
            <span className={classNames(styles.priceLink, styles.deactivated)}>
              <Vector
                type={'external'}
                colour="#000"
                className={styles.externalIcon}
              />
              {_t(content[0].buttonText)}
            </span>
          ) : (
            <a
              className={styles.priceLink}
              href={content[0].buttonUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Vector
                type={'external'}
                colour="#000"
                className={styles.externalIcon}
              />
              {_t(content[0].buttonText)}
            </a>
          )}
        </div>
      </div>
    );
  }
};

export default InfoBlock;
