import React from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import styles from './ImageOrVideo.module.scss';
import t from '../../utils/translate';
import { fluidImagePropHelper } from '../../utils/imagePropHelper';

export default function ImageOrVideo({ content, subpage }) {
  const { videoPoster, alt, asset, _type } = content;

  let sources;

  if (subpage) {
    sources = fluidImagePropHelper(asset.id, 800, 530);
  } else {
    sources = asset.fluid;
  }

  let media;

  if (_type === 'mediaImage') {
    media = <Img alt={alt} className={styles.image} fluid={sources} />;
  } else if (_type === 'videoUpload') {
    media = (
      <video
        autoPlay
        loop
        muted
        playsInline
        controls={false}
        src={asset.url}
        poster={videoPoster ? videoPoster.asset.fixed.src : null}
      >
        {t('videoAlert')}
      </video>
    );
  }
  return (
    <div
      className={classNames(styles.assetOuter, subpage ? styles.subpage : null)}
    >
      {media}
    </div>
  );
}
