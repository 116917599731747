import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity';

const {
  api: { projectId, dataset },
} = require('../../cms/sanity.json');

export const fixedImagePropHelper = (assetId, width, height) => {
  const sanityConfig = { projectId: projectId, dataset: dataset };

  const fixedProps = getFixedGatsbyImage(
    assetId,
    { width: width, height: height },
    sanityConfig
  );

  return fixedProps;
};

export const fluidImagePropHelper = (assetId, width, height) => {
  const sanityConfig = { projectId: projectId, dataset: dataset };

  const fluidProps = getFluidGatsbyImage(
    assetId,
    { maxWidth: width, maxHeight: height },
    sanityConfig
  );

  return fluidProps;
};
