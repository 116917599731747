import React, {useEffect, useRef} from 'react';
import { useLocation } from '@reach/router';
import t from '../utils/translate';

const useSetLocale = () => {
  const location = useLocation();
  const willMount = useRef(true);

  if (willMount.current) {
    setLocale(t, location);
  }

  willMount.current = false;

  useEffect(() => {
    setLocale(t, location);
  }, [location])
}

const setLocale = (t, location) => {
  const { pathname } = location;
  let locale = 'nb';

  if (pathname.indexOf('/en') === 0) {
    locale = 'en';
    t.setLocale(locale)
  }

  t.setLocale(locale)
}

export default useSetLocale;
